/* eslint-disable indent */
import { Notification, NotificationType } from 'api/notifications'
import { TypeToContentMap } from 'constants/notifications'
import routes from 'constants/routes'
import { Translate } from 'next-translate'
import { formatCurrency } from 'utils/strings'
import {
  getArtistUrl,
  getArtworkUrl,
  getArtworkUrlFromRoot,
  getBuyNowUrl,
  getEditAskUrl,
  getEditBidUrl,
  getOrderUrl,
  getSaleUrl,
  getSellNowUrl,
} from 'utils/urls'

export const getNotificationContent = (
  {
    type,
    groupingCount,
    artwork,
    context,
    hasAsk,
    hasBid,
    parentId,
  }: Notification,
  t: Translate
) => {
  const content = TypeToContentMap[type] ?? {}
  let { title, action, secondaryAction } = content
  const { description, actionTheme, secondaryActionTheme } = content

  const isNotificationGrouped = Boolean(groupingCount && groupingCount > 1)

  const artworkName = artwork?.name || ''
  const artistName =
    artwork?.artists?.map(artist => artist.name).join(' x ') || ''
  const price = formatCurrency(context?.price || 0, {
    minimumFractionDigits: 2,
  })

  const translationPayload = {
    price,
    highestBid: artwork?.highestBid
      ? formatCurrency(artwork.highestBid / 100, {
          minimumFractionDigits: 2,
        })
      : '',
    lowestAsk: artwork?.lowestAsk
      ? formatCurrency(artwork.lowestAsk / 100, {
          minimumFractionDigits: 2,
        })
      : '',
    ownBid: hasBid?.price
      ? formatCurrency(hasBid.price / 100, { minimumFractionDigits: 2 })
      : '',
    ownAsk: hasAsk?.price
      ? formatCurrency(hasAsk.price / 100, { minimumFractionDigits: 2 })
      : '',
    nrAsks: context?.nrAsks || '',
    nrBids: context?.nrBids || '',
  }

  switch (type) {
    case NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW:
    case NotificationType.NEW_LOWEST_ASK_SAVED_ARTWORK:
    case NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST:
      // don't show the "buy now" button if the current lowest ask does not have the same value as in the context
      if (artwork?.lowestAsk !== context?.price) {
        action = undefined
      }
      if (isNotificationGrouped) {
        title = 'x_new_lowest_listings'
      }
      break

    // don't show the "buy now" button if the artwork does not have a lowest ask because the user cannot sell the item
    case NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW: // kept for backwards compatibility
    case NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST:
    case NotificationType.NEW_HIGHEST_BID_SAVED_ARTWORK:
      if (!artwork?.lowestAsk) {
        action = undefined
      }
      break

    case NotificationType.NEW_HIGHEST_BID_BUYER:
    case NotificationType.NEW_LOWEST_ASK_BUYER:
      if (!hasBid?.price) {
        action = undefined
      }

      if (!artwork?.lowestAsk) {
        secondaryAction = undefined
      }
      break

    // don't show the "sell now" button if the artwork does not have a highest bid because the user cannot buy the item
    case NotificationType.NEW_HIGHEST_BID_SELLER:
    case NotificationType.NEW_LOWEST_ASK_SELLER:
      if (!hasAsk?.price) {
        action = undefined
      }

      if (!artwork?.highestBid) {
        secondaryAction = undefined
      }
      break

    case NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED:
      if (parentId) {
        title = 'new_highest_offer'
      }
      break

    case NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED:
      if (parentId) {
        title = 'new_lowest_listing'
      }
      break
  }

  return {
    title: t(title, translationPayload),
    action: action ? t(action, translationPayload) : undefined,
    secondaryAction: secondaryAction
      ? t(secondaryAction, translationPayload)
      : undefined,
    description: description
      ? t(description, { artworkName, artistName, price })
      : undefined,
    actionTheme,
    secondaryActionTheme,
  }
}

export const getActionsBehavior = (
  props: Notification
): { primary: string; secondary?: string } => {
  const { context, type, artwork, groupingCount } = props

  const defaultReturn = { primary: '#' }

  if (!context || !type || !artwork) return defaultReturn

  const { artists = [], name: artworkName = '', id: artworkId } = artwork
  const { askId, bidId, orderId, dhlInsurance } = context

  switch (type) {
    // upload auth docs
    case NotificationType.SALE_CONFIRMATION_PASS_AUTH: {
      return {
        primary: getArtworkUrlFromRoot(
          'ask-or-sell/submit-documents',
          artists,
          artworkName,
          artworkId
        ),
      }
    }

    // view order details
    case NotificationType.ORDER_CONFIRMATION: {
      if (!askId || !bidId || !orderId) return defaultReturn
      return { primary: getOrderUrl(artworkId, askId, bidId, orderId) }
    }

    // view sale details
    case NotificationType.SALE_CONFIRMATION: {
      if (!askId || !bidId || !orderId) return defaultReturn
      return { primary: getSaleUrl(artworkId, askId, bidId, orderId) }
    }

    // update listing
    case NotificationType.SALE_BY_ANOTHER_SELLER:
    case NotificationType.NEW_HIGHEST_BID_SELLER:
    case NotificationType.NEW_LOWEST_ASK_SELLER: {
      return {
        primary: getEditAskUrl(artists, artworkName, artworkId),
        secondary: getSellNowUrl(artists, artworkName, artworkId, dhlInsurance),
      }
    }

    // update offer
    case NotificationType.ORDER_BY_ANOTHER_BUYER:
    case NotificationType.NEW_HIGHEST_BID_BUYER:
    case NotificationType.NEW_LOWEST_ASK_BUYER: {
      return {
        primary: getEditBidUrl(artists, artworkName, artworkId),
        secondary: getBuyNowUrl(artists, artworkName, artworkId),
      }
    }

    // view bid/view bid
    case NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW:
    case NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW: {
      const artworkUrl = getArtworkUrl(artists, artworkName, artworkId)

      if (groupingCount && groupingCount > 0) {
        const artist = artists[0]
        if (!artist) return { primary: artworkUrl }
        return {
          primary: getArtistUrl(artist.name, artist.id) + '?sort=Lowest+Ask',
        }
      }

      return { primary: artworkUrl }
    }

    // extend offer
    case NotificationType.BID_EXPIRED_1day:
    case NotificationType.BID_EXPIRED_7days: {
      return { primary: routes.myAccount.buying }
    }

    // extend listing
    case NotificationType.ASK_EXPIRED_1day:
    case NotificationType.ASK_EXPIRED_7days: {
      return { primary: routes.myAccount.selling }
    }

    // renew offer
    case NotificationType.BID_EXPIRED: {
      return { primary: `${routes.myAccount.buying}?tab=expired_offers` }
    }

    // renew listing
    case NotificationType.ASK_EXPIRED: {
      return { primary: `${routes.myAccount.selling}?tab=expired_listings` }
    }

    default:
      return { primary: '#' }
  }
}

export function isFollowedArtistNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_FOLLOW_ARTIST ||
    type === NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW ||
    type === NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW ||
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST ||
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}

export function isSavedArtworkNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_SAVE_ARTWORK ||
    type === NotificationType.NEW_LOWEST_ASK_SAVED_ARTWORK ||
    type === NotificationType.NEW_HIGHEST_BID_SAVED_ARTWORK
  )
}

export function shouldDisplayArtistUrl(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_FOLLOW_ARTIST ||
    type === NotificationType.NEW_SALE_SAVE_ARTWORK ||
    type === NotificationType.BID_EXPIRED ||
    type === NotificationType.ASK_EXPIRED ||
    type === NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW
  )
}

export function isArtistNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}

export function isCombinedNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}
