import { Notification } from 'api/notifications'
import classNames from 'classnames'
import { Tag } from 'components/notifications/Tag'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithClassName } from 'types'
import { isNearlyGone } from 'utils/artwork'
import { formatShortRelativeTime } from 'utils/dates'
import {
  isFollowedArtistNotification,
  isSavedArtworkNotification,
} from 'utils/notifications'

const dateTagThemes = {
  default: 'group-hover/notification:bg-cool-50',
  gray: '!bg-cool-50',
} as const

type Props = Notification &
  PropsWithClassName & {
    includeDateTag?: boolean
    dateTagTheme?: keyof typeof dateTagThemes
  }

export function NotificationTags({
  type,
  artwork,
  viewed,
  createdAt,
  className,
  includeDateTag,
  dateTagTheme = 'default',
}: Props) {
  const { t } = useTranslation('notifications')
  const _isFollowedArtist = isFollowedArtistNotification(type)
  const _isSavedArtwork = isSavedArtworkNotification(type)
  const _isNearlyGone = isNearlyGone(artwork?.spreadPercentage)

  if (
    !_isSavedArtwork &&
    !_isFollowedArtist &&
    !_isNearlyGone &&
    !includeDateTag
  )
    return null

  return (
    <div className={className}>
      {includeDateTag && (
        <Tag
          className={classNames(
            viewed ? 'bg-warm-100' : 'bg-cool-50',
            'text-cool-800 transition-colors',
            dateTagThemes[dateTagTheme]
          )}
        >
          {formatShortRelativeTime(new Date(createdAt))}
        </Tag>
      )}

      {_isFollowedArtist && (
        <Tag className='bg-purple-50 text-purple-600'>
          {t('followed_artist')}
        </Tag>
      )}

      {_isSavedArtwork && (
        <Tag className='bg-purple-50 text-purple-600'>{t('saved_artwork')}</Tag>
      )}

      {_isNearlyGone && (
        <Tag className='bg-warning-light text-warning-dark'>
          {t('nearly_gone')}
        </Tag>
      )}
    </div>
  )
}
